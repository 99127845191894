var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end","dense":""}},[(_vm.admin)?_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.$store.state.auth.corps,"item-text":function (ref) {
                var name = ref.name;
                var idcorp = ref.idcorp;

                return (name + " (Corp " + idcorp + ")");
},"item-value":"idcorp","outlined":"","dense":"","label":"Seleccionar Corporativo","no-data-text":"No se han encontrado corporativos","append-icon":"mdi-office-building"},model:{value:(_vm.dinamic_id_corp),callback:function ($$v) {_vm.dinamic_id_corp=$$v},expression:"dinamic_id_corp"}})],1):_vm._e(),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.$store.state.catalogs.allPlace,"item-text":function (ref) {
                var eventplace = ref.eventplace;
                var id_place = ref.id_place;

                return (id_place + "-" + eventplace);
},"item-value":"id_place","outlined":"","dense":"","label":"Seleccionar sitio","no-data-text":"No se han encontrado sitios","append-icon":"mdi-map-marker"},model:{value:(_vm.dinamic_id_place),callback:function ($$v) {_vm.dinamic_id_place=$$v},expression:"dinamic_id_place"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Seleccionar fecha inicio","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormattedIni),callback:function ($$v) {_vm.dateFormattedIni=$$v},expression:"dateFormattedIni"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuIni),callback:function ($$v) {_vm.menuIni=$$v},expression:"menuIni"}},[_c('v-date-picker',{attrs:{"locale":"es-MX","max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)},on:{"input":function($event){_vm.menuIni = false}},model:{value:(_vm.dateIni),callback:function ($$v) {_vm.dateIni=$$v},expression:"dateIni"}})],1)],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Selecciona fecha final","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormattedFin),callback:function ($$v) {_vm.dateFormattedFin=$$v},expression:"dateFormattedFin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFin),callback:function ($$v) {_vm.menuFin=$$v},expression:"menuFin"}},[_c('v-date-picker',{attrs:{"locale":"es-MX","max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)},on:{"input":function($event){_vm.menuFin = false}},model:{value:(_vm.dateFin),callback:function ($$v) {_vm.dateFin=$$v},expression:"dateFin"}})],1)],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":"","color":"primary","loading":_vm.buttonLoad},on:{"click":function($event){return _vm.getReport()}}},[_vm._v(" Generar")])],1),_c('v-col',{staticClass:"mt-4 mr-4",attrs:{"cols":"auto"}},[_c('v-btn',{class:{ 'custom-disabled': _vm.btnExport },attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.ExportarX($event)}}},[_vm._v(" Exportar")])],1)],1),_c('v-row',[(_vm.showTable)?_c('v-col',{attrs:{"cols":"12","id":"page-wrap"}},[_c('div',{staticClass:"space"},[_c('div',{staticClass:"card"},[_c('p',[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.data),function(item){return _c('table',{key:item.id_event,staticClass:"styled-table",staticStyle:{"border-collapse":"collapse"},attrs:{"border":"1","bordercolor":"#272727"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(item.eventplace))])]),_c('tr',[_c('th',[_vm._v("Concepto")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Vendidos")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Precio individual")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Importe")])])]),_c('tbody',[_vm._l((item.jsonb_agg),function(Alia){return _c('tr',{key:Alia.alias},[_c('td',[_vm._v(_vm._s(Alia.alias))]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(Alia.cantidad))]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.FormateadorMxn(Alia.price))+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.FormateadorMxn(Alia.total))+" ")])])}),_c('tr',[_c('th',{staticStyle:{"text-align":"right"},attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.FormateadorMxn(item.finalTotal))+" ")])])],2)])})],2)])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }