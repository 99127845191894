  const exportExcelJavascript = function(html, event, name){
  name = name.replaceAll(".", "")
  var link = document.createElement('a')
  link.href='data:application/vnd.ms-excel,' + escape(html);
  link.download= name
  link.click();
  link.remove()
  event.preventDefault();
};

const sumarDias = function(fecha, dias){
  fecha.setDate(fecha.getDate() + dias);
  var fecha1 = (new Date(fecha)).toISOString().substr(0, 10)
  return fecha1;
}

const sumarDias2 = function(fecha){
  let date    = fecha
  let month   = date.getMonth() + 1
  let year    = date.getFullYear()
  var fecha1
  if(month < 10){
      fecha1 = year+'-0'+month
  }else{
      fecha1 = year+'-'+month
  }
  return fecha1
}

const formatFecha = function(fecha, union) {
  var temp = fecha.split("-")
  return temp[2] + union + temp[1] + union + temp[0]
}


module.exports = {
  exportExcelJavascript,
  sumarDias,
  sumarDias2,
  formatFecha
}