<template>
  <div>
    <v-container fluid>
      <v-row dense justify="end">
        <v-col v-if="admin" cols="12" md="3" lg="3">
          <v-select
            :items="$store.state.auth.corps"
            :item-text="({ name, idcorp }) => `${name} (Corp ${idcorp})`"
            item-value="idcorp"
            outlined
            dense
            label="Seleccionar Corporativo"
            no-data-text="No se han encontrado corporativos"
            v-model="dinamic_id_corp"
            append-icon="mdi-select"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-select
            :items="$store.state.catalogs.allPlace"
            :item-text="({ eventplace, id_place }) => `${id_place} - ${eventplace}`"
            item-value="id_place"
            outlined
            dense
            label="Seleccionar sitio"
            no-data-text="No se han encontrado sitios"
            v-model="dinamic_id_place"
            append-icon="mdi-map-marker"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <v-menu
            v-model="menuIni"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedIni"
                outlined
                dense
                label="Seleccionar fecha inicio"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateIni"
              @input="menuIni = false"
              locale="es-MX"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <v-menu
            v-model="menuFin"
            :close-on-content-click="false"
            :nudge-right="-80"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedFin"
                outlined
                dense
                label="Selecciona fecha final"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateFin"
              @input="menuFin = false"
              locale="es-MX"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense justify="end">
        <v-col cols="auto">
          <v-checkbox
            v-model="allEvents"
            class="shrink mr-2 mt-0"
            label="Todos los eventos"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-select
            :items="events"
            :item-text="({ name, id_event }) => `${id_event} - ${name.ES}`"
            item-value="id_event"
            outlined
            dense
            label="Seleccionar evento"
            no-data-text="No se han encontrado eventos"
            v-model="dinamic_id_event"
            append-icon="mdi-stadium"
            :disabled="allEvents"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2" lg="2" class="d-flex justify-space-between">
          <v-btn
            dark
            color="primary"
            @click="getReport()"
            :loading="buttonLoad"
          >
            Generar</v-btn
          >
          <v-btn
            dark
            color="success"
            :class="{ 'custom-disabled': btnExport }"
            @click="ExportarX($event)"
          >
            Exportar</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense align="center">
        <v-col v-if="showTable" cols="12" id="page-wrap">
          <div class="space">
            <div class="card">
              <p>{{ title }}</p>
              <p>{{ text }}</p>
              <p>{{ text2 }}</p>
              <table
                class="styled-table"
                border="1"
                bordercolor="#272727"
                style="border-collapse: collapse"
                v-for="item in data"
                :key="item.id_event"
              >
                <thead>
                  <tr>
                    <th align="center" colspan="7">
                      {{ item.id_event }} - {{ item.name }}
                    </th>
                  </tr>
                  <tr>
                    <th>Boleto</th>
                    <th>Tipo persona</th>
                    <th align="center">Cantidad Caja</th>
                    <th align="center">Cantidad Web</th>
                    <th align="center">Cantidad Total</th>
                    <th align="center">Precio Unitario</th>
                    <th align="center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item2, index) in item.jsonb_agg" :key="index">
                    <td>{{ item2.alias }}</td>
                    <td>{{ item2.name_ticket_person }}</td>
                    <td align="center">{{ item2.cantidad_caja }}</td>
                    <td align="center">{{ item2.cantidad_web }}</td>
                    <td align="center">{{ item2.cantidad_total }}</td>
                    <td align="right">{{ FormateadorMxn(item2.price) }}</td>
                    <td align="right">{{ FormateadorMxn(item2.total) }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="font-weight: bold;">Total</td>
                    <td align="center" style="font-weight: bold;">{{ sumaAmountCaja(item.jsonb_agg) }}</td>
                    <td align="center" style="font-weight: bold;">{{ sumaAmountWeb(item.jsonb_agg) }}</td>
                    <td align="center" style="font-weight: bold;">{{ sumaAmount(item.jsonb_agg) }}</td>
                    <td></td>
                    <td align="right" style="font-weight: bold;">{{ FormateadorMxn(sumaTotal(item.jsonb_agg)) }}</td>
                  </tr>
                </tbody>
              </table>
              <table
                class="styled-table-type"
                border="1"
                bordercolor="#272727"
                style="border-collapse: collapse;min-width: 250px;"
              >
                <thead>
                  <tr>
                    <th align="center" colspan="2">Tipo de personas</th>
                  </tr>
                  <tr v-for="(item, index) in groupedTicketPerson" :key="index">
                    <th>{{ item.name_ticket_person }}</th>
                    <td align="right"> {{ item.cantidad}}</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td align="right" style="font-weight: bold;">{{sumaTypePerson(groupedTicketPerson)}}</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { EVENTMIXIN } from "@/mixins/eventsMixin";
import { exportExcelJavascript } from "@/utils/ReportsTools.js";
export default {
  name: "MenuReportType3",
  data(vm) {
    return {
      menuIni: false,
      dateIni: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormattedIni: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menuFin: false,
      dateFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormattedFin: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      dinamic_id_corp: null,
      dinamic_id_event: null,
      dinamic_id_place: null,
      events: null,
      corps: null,
      idCorp: null,
      data: null,
      showTable: false,
      buttonLoad: false,
      admin: false,
      btnExport: true,
      allEvents: false,
      groupedTicketPerson: null,
      text:null,
      text2:null
      
    };
  },
  mixins: [EVENTMIXIN],
  props: ["title"],
  watch: {
    dateIni(val) {
      this.dateFormattedIni = this.formatDate(this.dateIni);
    },
    dateFin(val) {
      this.dateFormattedFin = this.formatDate(this.dateFin);
    },
    dinamic_id_corp: function (val) {
      this.dinamic_id_place = null;
      this.dinamic_id_event = null;
      this.events = null;
      this.$store.dispatch("auth/setSelectedCorp", val);
      this.chargeDefaultPlace();
    },
    dinamic_id_place: function (val) {
      this.dinamic_id_event = null;
      this.events = null;
      if(this.dinamic_id_place!==null){
        this.getEvens();
      }
    },
  },
  mounted() {
    this.dinamic_id_corp = this.$store.state.auth.user.id_corp;
    this.admin = this.$store.state.auth.user.admin;
    if (!this.admin) {
      this.dinamic_id_corp = this.$store.state.auth.user.id_corp;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    getEvens() {
      this.$root
        .post("/Admin/GetEvents",{
          id_place: this.dinamic_id_place.id_place
        })
        .then((response) => {
          if (response.success === "FALSE") {
            this.$root.swalAlert(
              "error",
              "Error al cargar la lista de eventos",
              response.Msg
            );
            return;
          }
          this.events = response.data.Data;
          if (this.events) {
            this.dinamic_id_event = this.events[0].id_event;
          }
        })
        .catch((error) => {
          this.$root.swalAlert(
            "error",
            "Error al cargar la lista de eventos",
            error.message
          );
        })
        .finally(() => {});
    },
    getReport() {
      this.buttonLoad = true;
      this.$root
        .post("/Admin/ReportVentasEventos", {
          id_event: this.dinamic_id_event,
          start_date: this.dateIni,
          final_date: this.dateFin,
          all_events: this.allEvents,
          id_place: this.dinamic_id_place.id_place
        })
        .then((response) => {
          let info = response.data;
          if (info.success === "FALSE") {
            this.showTable = false;
            this.btnExport = true;
            this.$root.Toast("info", info.Msg);
            return;
          }
          this.data = response.data.data;
          //Agrupor por ticket Person
          const groupedTicketPerson = this.data.reduce((result, obj) => {
            const jsonbAgg = obj.jsonb_agg;

            jsonbAgg.forEach((item) => {
              const idTicketPerson = item.id_ticket_person;
              const nameTicketPerson = item.name_ticket_person;
                if (!result.hasOwnProperty(idTicketPerson)) {
                  result[idTicketPerson] = {
                    id_ticket_person: idTicketPerson===null?"NA":idTicketPerson,
                    name_ticket_person: idTicketPerson===null?"NA":nameTicketPerson,
                    cantidad: 0,
                  };
                }
                result[idTicketPerson].cantidad =
                  result[idTicketPerson].cantidad + parseInt(item.cantidad_total);
            });
            return result;
          }, {});
          this.groupedTicketPerson = Object.values(groupedTicketPerson);
          //Creación de titulos
          this.text = `Período del ${this.dateFormattedIni} al ${this.dateFormattedFin}`
          this.text2 = `Sitio: ${this.dinamic_id_place.id_place} - ${this.dinamic_id_place.eventplace}`
          this.showTable = true;
          this.btnExport = false;
        })
        .catch((error) => {
          this.showTable = false;
          this.btnExport = true;
          this.$root.swalAlert(
            "error",
            "Error al generar el reporte",
            error.message
          );
        })
        .finally(() => {
          this.buttonLoad = false;
        });
    },
    async chargeDefaultPlace() {
      await this.LoadPlace_Mixin();
      if (this.$store.state.catalogs.allPlace.length > 0) {
        this.dinamic_id_place = this.$store.state.catalogs.allPlace[0];
      }
    },
    FormateadorMxn(number) {
      if (!number) {
        number = 0;
      }
      if (typeof number === "string") {
        number = parseFloat(number);
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "usd",
        minimumFractionDigits: 0,
      });
      return formatter.format(number);
    },
    ExportarX: function (event) {
      var table = document.getElementById("page-wrap");
      var html = table.outerHTML;
      exportExcelJavascript(html, event, "Reporte");
    },
    sumaAmount(arr){
      if(arr){
        const sum = arr.reduce((acumulador, obj)=>{
          let variable = parseInt(obj.cantidad_total)
          if(isNaN(variable)){
            variable = 0
          }
          return acumulador + variable
        },0)
        return sum
      }
      return 0;
    },
    sumaAmountWeb(arr){
      if(arr){
        const sum = arr.reduce((acumulador, obj)=>{
          let variable = parseInt(obj.cantidad_web)
          if(isNaN(variable)){
            variable = 0
          }
          return acumulador + variable
        },0)
        return sum
      }
      return 0;
    },
    sumaAmountCaja(arr){
      if(arr){
        const sum = arr.reduce((acumulador, obj)=>{
          let variable = parseInt(obj.cantidad_caja)
          if(isNaN(variable)){
            variable = 0
          }
          return acumulador + variable
        },0)
        return sum
      }
      return 0;
    },
    sumaTotal(arr){
      if(arr){
        const sum = arr.reduce((acumulador, obj)=>{
          let variable = parseInt(obj.total)
          if(isNaN(variable)){
            variable = 0
          }
          return acumulador + variable
        },0)
        return sum
      }
      return 0;
    },
    sumaTypePerson(arr){
      if(arr){
        const sum = arr.reduce((acumulador, obj)=>{
          let variable = parseInt(obj.cantidad)
          if(isNaN(variable)){
            variable = 0
          }
          return acumulador + variable
        },0)
        return sum
      }
      return 0;
    }
  },
};
</script>
<style scoped>
p {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.space {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 8.5in; /* Tamaño carta: 8.5 pulgadas de ancho */
  height: auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}
@media screen and (max-width: 768px) {
  /* Ajustar tamaño de la tarjeta para pantallas pequeñas */
  .card {
    width: 90%; /* Porcentaje del ancho de la pantalla */
    height: auto; /* Ajustar automáticamente el alto */
  }
}
.styled-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  min-width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 600px) {
  .styled-table {
    width: 90%; /* Porcentaje del ancho de la pantalla */
    height: auto; /* Ajustar automáticamente el alto */
  }
}
.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}
.styled-table-type {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  min-width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: left;
}
@media (max-width: 600px) {
  .styled-table-type {
    width: 90%; /* Porcentaje del ancho de la pantalla */
    height: auto; /* Ajustar automáticamente el alto */
  }
}
.styled-table-type th {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}
.styled-table-type th,
.styled-table-type td {
  padding: 6px 8px !important;
}
.styled-table-type td:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}


.custom-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
</style>
