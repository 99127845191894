<template>
  <div>
    <div class="cards">
      <div
        v-for="report in reports"
        :key="report.id"
        :class="`card ${report.colors}`"
        @click="ReportFilters(report)"
      >
        <div class="overlay"></div>
        <div class="circle">
          <v-icon class="icon" size="80" :color="report.colorIcon">{{
            report.icon
          }}</v-icon>
        </div>
        <p>{{ report.title }}</p>
      </div>
    </div>
    <ReportDialog
      v-if="showReports"
      :showReports="showReports"
      :title="reportSelect.title"
      :type="reportSelect.id"
      @HiddeDialog="showReports = false"
    />
  </div>
</template>

<script>
import ReportDialog from "./ReportDialog.vue";
export default {
  name: "ContentReport",
  components: {
    ReportDialog,
  },
  data() {
    return {
      reports: [
        {
          id: 1,
          title: "Reporte de ventas totales",
          colors: "reporte_1",
          icon: "mdi-cash",
          colorIcon: "#FF9800",
        },
        {
          id: 2,
          title: "Reporte de ventas por sitio",
          colors: "reporte_2",
          icon: "mdi-cash-marker",
          colorIcon: "#4CAF50",
        },
        {
          id: 3,
          title: "Reporte de ventas por evento",
          colors: "reporte_3",
          icon: "mdi-account-cash",
          colorIcon: "#B71C1C",
        },
      ],
      reportSelect: null,
      showReports: false,
    };
  },
  methods: {
    ReportFilters(item) {
      this.reportSelect = item;
      this.showReports = true;
    },
  },
};
</script>

<style scoped>
.cards {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Open Sans";
}
.reporte_1 {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}
.reporte_2 {
  --bg-color: #a5d6a7;
  --bg-color-light: #e2fced;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}
.reporte_3 {
  --bg-color: #e57373;
  --bg-color-light: #ffcdd2;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(249, 195, 184, 0.48);
}
.card {
  width: 220px;
  height: 321px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border: 2px solid var(--bg-color);
  cursor: pointer;
  margin-bottom: 30px;
}

.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.card:hover .overlay {
  transform: scale(4) translateZ(0);
}

.card:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card:hover .circle:after {
  background: var(--bg-color-light);
}

.card:hover p {
  color: var(--text-color-hover);
}

.card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.card p {
  font-size: 17px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1;
  transition: color 0.3s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  transition: opacity 0.3s ease-out;
}

.circle .icon {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 70px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}
</style>
