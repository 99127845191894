<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span class="text-h5">Reportes</span>
          </v-card-title>
          <v-card-text>
            <ContentReport/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContentReport from "../../components/report/ContentReport.vue";
export default {
  name: "Reports",
  components: {
    ContentReport,
  },
  data(){
    return {
        
    }
  }
};
</script>
