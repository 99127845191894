<template>
  <div>
    <v-container fluid>
      <v-row justify="end" dense>
        <v-col v-if="admin" class="mt-4" cols="12" md="2" lg="2">
          <v-select
            :items="$store.state.auth.corps"
            :item-text="({ name, idcorp }) => `${name} (Corp ${idcorp})`"
            item-value="idcorp"
            outlined
            dense
            label="Seleccionar Corporativo"
            no-data-text="No se han encontrado corporativos"
            v-model="dinamic_id_corp"
            append-icon="mdi-office-building"
          ></v-select>
        </v-col>
        <v-col class="mt-4" cols="12" md="2" lg="2">
          <v-select
            :items="$store.state.catalogs.allPlace"
            :item-text="({ eventplace, id_place }) => `${id_place}-${eventplace}`"
            item-value="id_place"
            outlined
            dense
            label="Seleccionar sitio"
            no-data-text="No se han encontrado sitios"
            v-model="dinamic_id_place"
            append-icon="mdi-map-marker"
          ></v-select>
        </v-col>
        <v-col class="mt-4" cols="12" md="2" lg="2">
          <v-menu
            v-model="menuIni"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedIni"
                outlined
                dense
                label="Seleccionar fecha inicio"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateIni"
              @input="menuIni = false"
              locale="es-MX"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="mt-4" cols="12" md="2" lg="2">
          <v-menu
            v-model="menuFin"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedFin"
                outlined
                dense
                label="Selecciona fecha final"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateFin"
              @input="menuFin = false"
              locale="es-MX"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="mt-4" cols="auto">
          <v-btn
            dark
            color="primary"
            @click="getReport()"
            :loading="buttonLoad"
          >
            Generar</v-btn
          >
        </v-col>
        <v-col class="mt-4 mr-4" cols="auto">
          <v-btn dark color="success" :class="{ 'custom-disabled': btnExport }" @click="ExportarX($event)"> Exportar</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col v-if="!showTable" class="list_not_found" cols="12">
          <p>No se ha encontrado ningún registro</p>
        </v-col> -->
        <v-col v-if="showTable" cols="12" id="page-wrap">
          <div class="space">
            <div class="card">
              <p>{{ title }}</p>
              <table
                class="styled-table"
                border="1"
                bordercolor="#272727"
                style="border-collapse: collapse"
                v-for="item in data"
                :key="item.id_event"
              >
                <thead>
                  <tr>
                    <th colspan="4">{{ item.eventplace }}</th>
                  </tr>
                  <tr>
                    <th>Concepto</th>
                    <th style="text-align: center">Vendidos</th>
                    <th style="text-align: center">Precio individual</th>
                    <th style="text-align: center">Importe</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="Alia in item.jsonb_agg" :key="Alia.alias">
                    <td>{{ Alia.alias }}</td>
                    <td style="text-align: center">{{ Alia.cantidad }}</td>
                    <td style="text-align: right">
                      {{ FormateadorMxn(Alia.price) }}
                    </td>
                    <td style="text-align: right">
                      {{ FormateadorMxn(Alia.total) }}
                    </td>
                  </tr>
                  <tr>
                    
                    <th style="text-align: right" colspan="3">Total</th>
                    <th style="text-align: right">
                      {{ FormateadorMxn(item.finalTotal) }}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { EVENTMIXIN } from "@/mixins/eventsMixin";
import { exportExcelJavascript } from "@/utils/ReportsTools.js"
export default {
  name: "MenuReportType2",
  data(vm) {
    return {
      menuIni: false,
      dateIni: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormattedIni: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menuFin: false,
      dateFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormattedFin: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      dinamic_id_corp: null,
      dinamic_id_place: null,
      corps: null,
      idCorp: null,
      data: null,
      showTable: false,
      buttonLoad: false,
      admin: false,
      btnExport: true,
    };
  },
  mixins: [EVENTMIXIN],
  props: ["title"],
  watch: {
    dateIni(val) {
      this.dateFormattedIni = this.formatDate(this.dateIni);
    },
    dateFin(val) {
      this.dateFormattedFin = this.formatDate(this.dateFin);
    },
    dinamic_id_corp: function (val) {
      this.dinamic_id_place= null;
      this.$store.dispatch("auth/setSelectedCorp", val);
      this.chargeDefaultPlace();
    },
  },
  mounted() {
    this.dinamic_id_corp = this.$store.state.auth.user.id_corp;
    this.admin = this.$store.state.auth.user.admin;
    if (!this.admin) {
      this.dinamic_id_corp = this.$store.state.auth.user.id_corp;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async chargeDefaultPlace() {
      await this.LoadPlace_Mixin();
      if(this.$store.state.catalogs.allPlace.length > 0){
        this.dinamic_id_place = this.$store.state.catalogs.allPlace[0].id_place
      }
    },
    getReport() {
      this.buttonLoad = true;
      this.$root
        .post("/Admin/ReportVentasSitios", {
          id_place: this.dinamic_id_place,
          start_date: this.dateIni,
          final_date: this.dateFin,
        })
        .then((response) => {
          let info = response.data;
          if (info.success === "FALSE") {
            this.btnExport = true;
            this.showTable = false;
            this.$root.Toast("info", info.Msg);
            return;
          }
          this.data = response.data.data;
          this.showTable = true;
          this.btnExport = false;
        })
        .catch((error) => {
          this.showTable = false;
          this.btnExport = true;
          this.$root.swalAlert(
            "error",
            "Error al generar el reporte",
            error.message
          );
        })
        .finally(() => {
          this.buttonLoad = false;
        });
    },
    FormateadorMxn(number) {
      if (!number) {
        number = 0;
      }
      if (typeof number === "string") {
        number = parseFloat(number);
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "usd",
        minimumFractionDigits: 0,
      });
      return formatter.format(number);
    },
    ExportarX: function(event){
            var table= document.getElementById('page-wrap');
            var html = table.outerHTML;
            exportExcelJavascript(html, event, "Reporte")
        }
  },
};
</script>
<style scoped>
p {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.space {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 8.5in; /* Tamaño carta: 8.5 pulgadas de ancho */
  height: 11in; /* Tamaño carta: 11 pulgadas de alto */
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) {
  /* Ajustar tamaño de la tarjeta para pantallas pequeñas */
  .card {
    width: 90%; /* Porcentaje del ancho de la pantalla */
    height: auto; /* Ajustar automáticamente el alto */
  }
}
.styled-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  min-width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 600px) {
  .styled-table {
    width: 90%; /* Porcentaje del ancho de la pantalla */
    height: auto; /* Ajustar automáticamente el alto */
  }
}
.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

.content-table thead tr {
  background-color: #009879;
  color: white;
  text-align: center;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.custom-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
</style>
