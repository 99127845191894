<template>
  <v-dialog fullscreen v-model="dialog" persistent no-click-animation>
    <v-card flat color="#f7f7f7">
      <v-toolbar color="#FB8C00" dark elevation="1">
        <v-toolbar-title> Generación de reporte </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <menu-report-type-1 v-if="type === 1" :title="title"></menu-report-type-1>
      <menu-report-type-2 v-if="type === 2" :title="title"></menu-report-type-2>
      <menu-report-type-3 v-if="type === 3" :title="title"></menu-report-type-3>
    </v-card>
  </v-dialog>
</template>
<script>
import MenuReportType1 from './MenuReportType1.vue';
import MenuReportType2 from './MenuReportType2.vue';
import MenuReportType3 from './MenuReportType3.vue';
export default {
  name: 'ReportDialog',
  components: {
    MenuReportType1,
    MenuReportType2,
    MenuReportType3,
  },
  props: ['title', 'type', 'showReports'],
  computed: {
    dialog: {
      get() {
        return this.showReports;
      },
      set() {
        this.$emit("HiddeDialog");
      },
    },
  },
};
</script>